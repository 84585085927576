// Chakra imports
import {
    Flex,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Custom components
  import Card from "../components/Card/Card.js";
  import CardBody from "../components/Card/CardBody.js";
  import IconBox from "../components/Icon/IconBox";
  import React from "react";
  import { NumberFormat } from "../constants/number-format.js";
  const MiniStatistics = ({ title, amount, percentage, icon ,earnings,user}) => {
    const iconTeal = useColorModeValue("teal.300", "teal.300");
    const textColor = useColorModeValue("gray.700", "white");
  
    return (
      <Card minH='83px'>
        <CardBody>
          <Flex flexDirection='row' align='center' justify='center' w='100%'>
            <Stat me='auto'>
              <StatLabel
                fontSize='sm'
                color='gray.400'
                fontWeight='bold'
                pb='.1rem'>
                {title}
              </StatLabel>
                <div >
                <StatNumber fontSize='lg' color={textColor}>
              {amount}
             
                </StatNumber>
                </div>
                <StatNumber fontSize='sm' color={textColor}>
                {earnings}
           
                </StatNumber>
              
                {/* <StatHelpText
                  alignSelf='flex-end'
                  justifySelf='flex-end'
                  m='0px'
                  color={percentage > 0 ? "green.400" : "red.400"}
                  fontWeight='bold'
                  ps='3px'
                  fontSize='md'>
                  {percentage > 0 ? `+${percentage}%` : `${percentage}%`}
                </StatHelpText> */}
       
            </Stat>
            <IconBox as='box' h={"45px"} w={"45px"} bg={iconTeal}>
              {icon}
            </IconBox>
          </Flex>
        </CardBody>
      </Card>
    );
  };
  
  export default MiniStatistics;