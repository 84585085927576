import {
  Avatar,
  Badge,
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";

import React from "react";

function TablesTableRow(props) {
  const { id, coin, call, price, target, stoploss, timestamp } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");

  return (
    <Tr>
      <Td>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {id + 1}
          </Text>
          {/* <Text fontSize="sm" color="gray.400" fontWeight="normal">
            {subdomain}
          </Text> */}
        </Flex>
      </Td>
      <Td minWidth={{ sm: "250px" }} ps="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Flex direction="column">
            <Text
              fontSize="md"
              color={textColor}
              fontWeight="bold"
              minWidth="100%"
            >
              {coin}
            </Text>
            {/* <Text fontSize="sm" color="gray.400" fontWeight="normal">
              {coinId}
            </Text> */}
          </Flex>
        </Flex>
      </Td>


      <Td>
        <Badge
          bg={call === "buy" ? "green.400" : "red.500"}
          color={call === "buy" ? "white" : "white"}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {call}
        </Badge>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {price}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {target}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {stoploss}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {new Date(timestamp).toLocaleString()}
        </Text>
      </Td>
      {/* <Td>
        <Button p="0px" bg="transparent" variant="no-hover">
          <Text
            fontSize="md"
            color="gray.400"
            fontWeight="bold"
            cursor="pointer"
          >
            Edit
          </Text>
        </Button>
      </Td> */}
    </Tr>
  );
}

export default TablesTableRow;
