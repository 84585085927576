// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Next Import
import { useNavigate, useSearchParams } from 'react-router-dom'

// ** Axios
import axios from 'axios'

// ** Config
import authConfig from '../configs/auth'

// ** Defaults
const defaultProvider = {
    user: null,
    loading: true,
    setUser: () => null,
    setLoading: () => Boolean,
    isInitialized: false,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    setIsInitialized: () => Boolean,
    register: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
    // ** States
    const [user, setUser] = useState(defaultProvider.user)
    const [loading, setLoading] = useState(defaultProvider.loading)
    const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)

    // ** Hooks
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    useEffect(() => {
        const initAuth = async () => {
            setIsInitialized(true)
            const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)
            if (storedToken) {
                setLoading(true)
                await axios
                    .get(authConfig.meEndpoint, 
                        {
                        headers: {
                            Authorization: `Bearer ${storedToken}`
                        }
                    }
                    )
                    .then(async response => {
                        setLoading(false)
                        setUser({ ...response.data.userData })
                    })
                    .catch(() => {
                        localStorage.removeItem(authConfig.storageUserDataKeyName)
                        localStorage.removeItem(authConfig.storageTokenKeyName)
                        setUser(null)
                        setLoading(false)
                    })
            } else {
                setLoading(false)
            }
        }
        initAuth()
    }, [])

    const handleLogin = (params, errorCallback) => {
        axios
            .post(authConfig.loginEndpoint, params)
            .then(async res => {
                window.localStorage.setItem(authConfig.storageTokenKeyName, res.data.token)
            })
            .then(() => {
                axios
                    .get(authConfig.meEndpoint, {
                        headers: {
                            Authorization: `Bearer ${window.localStorage.getItem(authConfig.storageTokenKeyName)}`
                        }
                    })
                    .then(async response => {
                        const returnUrl = searchParams.get('returnUrl')
                        setUser({ ...response.data.userData })
                        await window.localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(response.data.userData))
                        const redirectURL = returnUrl && returnUrl !== '/' ? returnUrl : '/'
                        if (returnUrl) {
                            navigate(redirectURL)
                        } else {
                            navigate(redirectURL)
                        }
                    })
            })
            .catch(err => {
                if (errorCallback) errorCallback(err)
            })
    }

    const handleLogout = () => {
        setUser(null)
        setIsInitialized(false)
        window.localStorage.removeItem(authConfig.storageUserDataKeyName)
        window.localStorage.removeItem(authConfig.storageTokenKeyName)
        navigate('/login')
    }

    const handleRegister = (params, errorCallback) => {
        axios
            .post(authConfig.registerEndpoint, params)
            .then(res => {
                if (res.data.error) {
                    if (errorCallback) errorCallback(res.data.error)
                } else {
                    handleLogin({ email: params.email, password: params.password })
                }
            })
            .catch(err => (errorCallback ? errorCallback(err) : null))
    }

    const values = {
        user,
        loading,
        setUser,
        setLoading,
            isInitialized,
        setIsInitialized,
        login: handleLogin,
        logout: handleLogout,
        register: handleRegister
    }

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }