import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Stack,
    TabList,
    TabPanel,
    TabPanels,
    Table,
    Tabs,
    Tbody,
    Text,
    Textarea,
    Th,
    Thead,
    Tr,
    Td,
    Tab,
    Icon,
    useColorModeValue,
    InputLeftElement,
    IconButton,
    InputGroup
} from '@chakra-ui/react'
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';
// Custom components
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Select } from '@chakra-ui/react'
import moment from 'moment/moment';
import Pagination from '../Dashboard/Pagination';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { PlanNameHelper } from '../../constants/general';
import PickersRange from '../Datepicker';
import { axiosInstance } from '../../network';
import { ApiEndPoints } from '../../network/endpoint';
function Payments() {
    // const columnHelper = createColumn;
    // const columns = [
    //     columnHelper.accessor('name', {
    //       cell: info => info.getValue(),
    //       header: 'Name',
    //     }),
    //     columnHelper.accessor('email', {
    //       cell: info => info.getValue(),
    //       header: 'Email',
    //     }),
    //     columnHelper.accessor('phone', {
    //       cell: info => info.getValue(),
    //       header: 'Phone',
    //     }),
    //     columnHelper.accessor('birthday', {
    //       cell: info => info.getValue(),
    //       header: 'Birthday',
    //     }),
    //     columnHelper.accessor('action', {
    //       cell: info => info.getValue(),
    //       header: '',
    //     }),
    //   ];
    const customIconStyle = {
        color: 'darkgreen',
        height: '24px',
        width: '24px',
    };
    const customIconStylewarning = {
        color: 'red',
        height: '24px',
        width: '24px',
    };
    const textColor = useColorModeValue("gray.700", "white");
    let searchIcon = useColorModeValue("gray.700", "gray.200");
    let mainText = useColorModeValue("gray.700", "gray.200");
    let mainTeal = useColorModeValue("teal.300", "teal.300");
    let inputBg = useColorModeValue("white", "gray.800");
    const storedToken = `Bearer ${window.localStorage.getItem("VEROXAI_access_token")}`
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [plan, setPlan] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalpage] = useState("");
    const [startDateRange, setStartDateRange] = useState(null);
    const [endDateRange, setEndDateRange] = useState(null);
    const fetchTableData = () => {
        setLoading(true);
        const headers = {
            'Authorization': storedToken,
            'Content-Type': 'application/json',
        }
        const params = {
            f_plan: plan,
            page: currentPage,
            f_timestamp: `${moment(startDateRange).format("DD/MM/YYYY")},${moment(endDateRange).format("DD/MM/YYYY")}`
        }
     
        axiosInstance
            .get(ApiEndPoints.admin.Payments,{params: params})
            .then(async res => {
                setData(res.data.payments);
                setTotalpage(res.data.totalPages);
                // setTotaldata(res.data.total)
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handlePlanChange = (event) => {
        setPlan(event.target.value);
    };

    useEffect(() => {
        fetchTableData();
    }, [plan, currentPage, startDateRange, endDateRange])

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <>
            <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
                <Flex justify="space-between" align="center">

                    <Text
                        color={textColor}
                        fontWeight="bold"
                        fontSize="3xl"
                    >
                        Payments
                    </Text>
                    <Flex justify="space-between" align="center">
                        {/* <InputGroup
                            cursor="pointer"
                            bg={inputBg}
                            borderRadius="15px"
                            w={{
                                sm: "128px",
                                md: "200px",
                            }}
                            me={{ sm: "auto", md: "20px" }}
                            _focus={{
                                borderColor: { mainTeal },
                            }}
                            _active={{
                                borderColor: { mainTeal },
                            }}
                        >
                            <InputLeftElement
                                children={
                                    <IconButton
                                        bg="inherit"
                                        borderRadius="inherit"
                                        _hover="none"
                                        _active={{
                                            bg: "inherit",
                                            transform: "none",
                                            borderColor: "transparent",
                                        }}
                                        _focus={{
                                            boxShadow: "none",
                                        }}
                                        icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
                                    ></IconButton>
                                }
                            />
                            <Input
                                fontSize="xs"
                                py="11px"
                                color={mainText}
                                placeholder="Type here..."
                                borderRadius="inherit"
                            />
                        </InputGroup> */}
                        <Box mr="20px">
                            <Text fontSize="s" mb="4px">
                                Date
                            </Text>
                            <PickersRange startDateRange={startDateRange} endDateRange={endDateRange} onChange={(dates) => {
                                const [start, end] = dates;
                                setStartDateRange(start);
                                setEndDateRange(end);
                                setCurrentPage(1);
                            }} />
                        </Box>


                    </Flex>
                </Flex>
                <Box overflowX="auto" mt="80px">
                    <Table >
                        <Thead>
                            <Tr>
                                <Th fontSize="lg">User</Th>
                                <Th fontSize="lg">Plan</Th>
                                <Th fontSize="lg">Amount</Th>
                                <Th fontSize="lg">Timestamp</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.map((item, indexe) => {
                                const timestamp = moment(item.created_at).utc().format('llll');
                                return <>
                                    <Tr key={item._id}>
                                        <Td>{item?.userData?.email || ""}</Td>
                                        <Td style={{ display: 'flex', alignItems: 'center' }}>
                                            <span >{PlanNameHelper[item.before.plan]}</span>
                                            <HiOutlineArrowNarrowRight style={{ marginRight: '2px', marginLeft: '2px' }} />
                                            <span style={{ marginLeft: '2px' }}>{PlanNameHelper[item.after.plan]}</span>
                                        </Td>
                                        <Td>VRX {item?.transaction?.erc20Transfers[0]?.valueWithDecimals}</Td>
                                        <Td>{timestamp}</Td>
                                    </Tr>
                                </>
                            })}
                            {/* Add more rows here */}
                        </Tbody>

                    </Table>
                    <Pagination
                        loading={loading}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Box>

            </Flex>
        </>
    )
}

export default Payments