// ** React Imports
import { useEffect } from 'react'

// ** Next Imports
import { useNavigate, useLocation } from 'react-router-dom'

// ** Hooks Import
import { useAuth } from '../../hooks/useAuth'

// ** Config
import authConfig from '../../configs/auth'

const UnGuardedPaths = [
    '/login',
    '/register',
    '/forgot-password',
    '/privacy-policy',
]

const AuthGuard = props => {
    const { children, fallback } = props
    const auth = useAuth()

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(
        () => {
            const currentPath = location.pathname + location.search + location.hash
            if (auth.user === null && !window.localStorage.getItem(authConfig.storageUserDataKeyName)) {
                if (!UnGuardedPaths.includes(location.pathname)) {
                    if (currentPath !== '/') {
                        navigate(`/login?returnUrl=${currentPath}`)
                    } else {
                        navigate('/login')
                    }
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    if (!UnGuardedPaths.includes(location.pathname) && (auth.loading || auth.user === null)) {
        return fallback
    }

    return children
}

export default AuthGuard