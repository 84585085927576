import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Stack,
    TabList,
    TabPanel,
    TabPanels,
    Table,
    Tabs,
    Tbody,
    Text,
    Textarea,
    Th,
    Thead,
    Tr,
    Td,
    Tab,
    Icon,
    useColorModeValue,
    InputLeftElement,
    IconButton,
    InputGroup
} from '@chakra-ui/react'
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';
// Custom components
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Select } from '@chakra-ui/react'
import moment from 'moment/moment';
import Pagination from '../Dashboard/Pagination';
import JsonTable from '../../components/Tables/JsonTable';
import PickersRange from '../Datepicker';
import { MultiSelect } from "react-multi-select-component";
import AsyncSelect from 'react-select/async'
import { axiosInstance } from '../../network';
import { ApiEndPoints } from '../../network/endpoint';

// import {  createColumn } from 'react-chakra-pagination';
const options = [
    { label: "BTCUSDT ", value: "bitcoin" },
    { label: "ETHUSDT", value: "ethereum" },
    { label: "BNBUSDT", value: "binancecoin" },
    { label: "NEOUSDT", value: "neo" },
    { label: "LTCUSDT", value: "litecoin" },
    { label: "XRPUSDT", value: "ripple" },
    { label: "EOSUSDT", value: "eos" },
    { label: "DOGEUSDT", value: "dogecoin" },
    { label: "LINKUSDT", value: "chainlink" },
    { label: "UNIUSDT", value: "uniswap" },
    { label: "AVAXUSDT", value: "avalanche-2" },
    { label: "ATOMUSDT", value: "cosmos" },
    { label: "SHIBUSDT", value: "shiba" },
    { label: "ICPUSDT", value: "internet-computer" },
    { label: "MATICUSDT", value: "matic-network" },
    { label: "DOTUSDT", value: "polkadot" },
    { label: "ALGOUSDT", value: "algorand" },
    { label: "BCHUSDT", value: "bitcoin-cash" },
    { label: "TOMOUSDT", value: "tomochain" },
    { label: "INJUSDT", value: "injective-protocol" },
];
function convertUnixTimestampToDateFormat(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is 0-based, so add 1
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
}
const unixTimestamp = 1695114000;
const formattedDate = convertUnixTimestampToDateFormat(unixTimestamp);
console.log(formattedDate); // Output: "16/09/2023"

function formatRelativeTimeFromNow(timestamp) {
    const now = moment();
    const diffInMinutes = now.diff(timestamp, 'minutes');
    const days = Math.floor(diffInMinutes / (60 * 24));
    const hours = Math.floor((diffInMinutes % (60 * 24)) / 60);
    const minutes = diffInMinutes % 60;

    let formattedTime = '';

    if (days > 0) {
        formattedTime += days + 'd';
    }

    if (hours > 0) {
        formattedTime += ' ' + hours + 'h';
    }

    if (minutes > 0 || (days === 0 && hours === 0)) {
        formattedTime += ' ' + minutes + 'm';
    }

    return `${formattedTime.trim()} ago`;
}

moment.locale('en', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: 'seconds',
        ss: '%ss',
        m: 'a minute',
        mm: '%dm',
        h: 'an hour',
        hh: '%dh',
        d: 'a day',
        dd: '%dd',
        M: 'a month',
        MM: '%dM',
        y: 'a year',
        yy: '%dY'
    }
});
function ResentSignals() {
    const textColor = useColorModeValue("gray.700", "white");
    let searchIcon = useColorModeValue("gray.700", "gray.200");
    let mainText = useColorModeValue("gray.700", "gray.200");
    let mainTeal = useColorModeValue("teal.300", "teal.300");
    let inputBg = useColorModeValue("white", "gray.800");
    const storedToken = `Bearer ${window.localStorage.getItem("VEROXAI_access_token")}`
    const [data, setData] = useState([]);
    const [call, setcall] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalpage] = useState("");
    const [loading, setLoading] = useState(true);
    const [startDateRange, setStartDateRange] = useState(null);
    const [endDateRange, setEndDateRange] = useState(null);
    const [selectedCoin, setSelectedCoin] = useState([]);
    const [coinOptions, setCoinOptions] = useState([]);
    const fetchCoinList = () => {
        axios
            .get("https://coinfeed.veroxai.io/coins/list")
            .then(async res => {
                setCoinOptions(res.data.data.map((o) => ({
                    "value": o.id,
                    "label": o.name,
                    "symbol": o.symbol,
                })).slice(0, 10))
            })
            .catch(err => {

            })
    }
    useEffect(() => {
        fetchCoinList();
    }, [])
    const fetchTableData = () => {
        setLoading(true)
        const params = {
            pages:currentPage,
            f_coin: selectedCoin.map((item, i) => (item.symbol)).join(',').toUpperCase(),
            f_timestamp: `${moment(startDateRange).format("DD/MM/YYYY")},${moment(endDateRange).format("DD/MM/YYYY")}`,
            f_call: call,

        }
        const queryString = new URLSearchParams(params).toString();
        const apiUrl = `https://admin-api.veroxai.io/signals/recent?${queryString}`;
        axiosInstance
            .get(ApiEndPoints.admin.Resent_Signals,{params:params})
            .then(async res => {
                setData(res.data.signals);
                setTotalpage(res.data.totalPages);
                // setTotaldata(res.data.total)
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handlecallChange = (event) => {
        setcall(event.target.value);
        setCurrentPage(1);
    };
    const handleOnChangeCoin = (selectedCoin) => {

        setSelectedCoin(selectedCoin);
        setCurrentPage(1);
    }

    useEffect(() => {
        fetchTableData();
    }, [selectedCoin, startDateRange, endDateRange, call,currentPage])

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);

    };
    const loadOptions = async (inputValue) => {

        let result = await fetch(`https://coinfeed.veroxai.io/coins-list?q=${inputValue}`)
            .then(res => res.json())
            .then(res => res.data)
            .catch(err => []);
        return result.map((o) => ({
            "value": o.coin_id,
            "label": o.name,
            "symbol": o.symbol,
        }));
    };
    const customStyles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
    }

    return (
        <>
            <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
                <Flex justify="space-between" align="center">
                    <Text
                        color={textColor}
                        fontWeight="bold"
                        fontSize="3xl"
                    >
                        Recent Signals
                    </Text>
                    <Flex justify="space-between" align="center">
                        {/* <InputGroup
                            cursor="pointer"
                            bg={inputBg}
                            borderRadius="15px"
                            w={{
                                sm: "128px",
                                md: "200px",
                            }}
                            me={{ sm: "auto", md: "20px" }}
                            _focus={{
                                borderColor: { mainTeal },
                            }}
                            _active={{
                                borderColor: { mainTeal },
                            }}
                        >
                            <InputLeftElement
                                children={
                                    <IconButton
                                        bg="inherit"
                                        borderRadius="inherit"
                                        _hover="none"
                                        _active={{
                                            bg: "inherit",
                                            transform: "none",
                                            borderColor: "transparent",
                                        }}
                                        _focus={{
                                            boxShadow: "none",
                                        }}
                                        icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
                                    ></IconButton>
                                }
                            />
                            <Input
                                fontSize="xs"
                                py="11px"
                                color={mainText}
                                placeholder="Type here..."
                                borderRadius="inherit"
                            />
                        </InputGroup> */}
                        <Flex justify="space-between" align="center">
                            <Box mr="20px">
                                <Text fontSize="s" mb="4px">
                                    Date
                                </Text>
                                <PickersRange startDateRange={startDateRange} endDateRange={endDateRange} onChange={(dates) => {
                                    const [start, end] = dates;
                                    setStartDateRange(start);
                                    setEndDateRange(end);
                                    setCurrentPage(1);
                                }} />
                            </Box>
                            <Box mr="20px">
                                <Text fontSize="s" mb="4px">
                                    coin
                                </Text>
                                <div style={{ width: '200px' }}>
                                <AsyncSelect
                                    isMulti
                                    cacheOptions
                                    defaultOptions
                                    value={selectedCoin}
                                    styles={customStyles}
                                    loadOptions={loadOptions}
                                    onChange={handleOnChangeCoin}

                                />
                                </div>
                            </Box>

                            <Box>
                                <Text fontSize="s" mb="4px">
                                    call
                                </Text>
                                <Select width="200px" value={call} onChange={handlecallChange}>
                                    <option value="">All</option>
                                    <option value="B">Buy</option>
                                    <option value="S">Sell</option>
                                </Select>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
                <Box overflowX="auto" mt="80px">
                    <Table >
                        <Thead>
                            <Th fontSize="lg">coin</Th>
                            <Th fontSize="lg">price</Th>
                            <Th fontSize="lg">side</Th>
                            <Th fontSize="lg">text</Th>
                            <Th fontSize="lg">Time</Th>
                            <Th fontSize="lg">color</Th>

                        </Thead>
                        <Tbody>
                            {data.map((item, index) => {
                                return <>
                                    <Tr key={item._id}>
                                        <Td>{item.coin}</Td>
                                        <Td>{item.price}</Td>
                                        <Td>{item.side}</Td>
                                        <Td>{item.text}</Td>
                                        <Td>
                                            {formatRelativeTimeFromNow(moment(new Date(item.time * 1000)))}{' '}
                                            ({convertUnixTimestampToDateFormat(item.time)})
                                        </Td>
                                        <Td>{item.color}</Td>
                                    </Tr>
                                </>


                            })}
                        </Tbody>

                    </Table>
                    <Pagination
                        loading={loading}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Box>

            </Flex>
        </>
    )
}

export default ResentSignals