import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { Spinner } from '@chakra-ui/react'

import AuthLayout from './layouts/Auth';
import MainLayout from './layouts/Main';
import AuthGuard from './components/auth/AuthGuard';
import AdminCell from './pages/AdminCells';
import User from './pages/User';
import Logs from './pages/Logs';
import Payments from './pages/Payments';
import ActiveTrades from './pages/Activetrades';
import ResentSignals from './pages/Resentsignals';

/** Apps Routes */
const LoginPage = React.lazy(() => import('./pages/Login'))
const DashboardPage = React.lazy(() => import('./pages/Dashboard'))

function App() {
  return (
    <Suspense fallback={<Spinner />}>
      <AuthGuard>
        <Routes>
          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            <Route path='/login' element={<LoginPage />} />
          </Route>

          <Route
            path='/'
            element={
              <MainLayout>
                <Outlet />
              </MainLayout>
            }
          >
            <Route path='' element={<DashboardPage />} />
          </Route>
          <Route
            path='/admin/calls'
            element={
              <MainLayout>
                <Outlet />
              </MainLayout>
            }
          >
            <Route path='' element={<AdminCell />} />
          </Route>
          <Route
            path='/user'
            element={
              <MainLayout>
                <Outlet />
              </MainLayout>
            }
          >
            <Route path='' element={<User />} />
          </Route>
          <Route
            path='/logs'
            element={
              <MainLayout>
                <Outlet />
              </MainLayout>
            }
          >
            <Route path='' element={<Logs />} />
          </Route>
          <Route
            path='/payments'
            element={
              <MainLayout>
                <Outlet />
              </MainLayout>
            }
          >
            <Route path='' element={<Payments />} />
          </Route>
          <Route
            path='/activetrades'
            element={
              <MainLayout>
                <Outlet />
              </MainLayout>
            }
          >
            <Route path='' element={<ActiveTrades />} />
          </Route>
          <Route
            path='/resentsignals'
            element={
              <MainLayout>
                <Outlet />
              </MainLayout>
            }
          >
            <Route path='' element={<ResentSignals />} />
          </Route>
          <Route path='*' element={<Navigate to='/404' replace />} />
        </Routes>
      </AuthGuard>
    </Suspense>
  );
}

export default App;
