// Chakra imports
import { Box, ChakraProvider, Portal } from '@chakra-ui/react';
import Footer from '../components/Footer/Footer.js';

// core components
import AuthNavbar from '../components/Navbars/AuthNavbar';
import React from 'react';
import { Redirect, Route, Switch, Outlet } from 'react-router-dom';
import theme from '../theme/theme';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppConfig from '../configs/appConfig';

export default function Pages(props) {
    const { children, ...rest } = props;
    // ref for the wrapper div
    const wrapper = React.createRef();
    React.useEffect(() => {
        document.body.style.overflow = 'unset';
        // Specify how to clean up after this effect:
        return function cleanup() { };
    });

    const navRef = React.useRef();
    document.documentElement.dir = 'ltr';
    document.documentElement.layout = 'auth';
    return (
        <ChakraProvider theme={theme} resetCss={false} w='100%'>
            <Box ref={navRef} w='100%'>
                <Box w='100%'>
                    <Box ref={wrapper} w='100%'>
                        {children}
                    </Box>
                </Box>
                <Box px='24px' mx='auto' width='1044px' maxW='100%'>
                    <Footer />
                </Box>
            </Box>
        </ChakraProvider>
    );
}
