import react from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
} from '@chakra-ui/react';
import { Table, TableContainer, Tbody, Thead, Tr, Th, Td } from "@chakra-ui/react";

const LogsView = (props) => {
    const { isOpen, toggle, data } = props;
  
    const renderValue = (value) => {
      if (typeof value === 'string') {
        return value;
      } else if (Array.isArray(value)) {
        return (
          <ul>
            {value.map((item, index) => (
              <li key={index}>{renderValue(item)}</li>
            ))}
          </ul>
        );
      } else if (typeof value === 'object') {
        return (
          <Table size="sm" colorScheme="gray">
            <Tbody>
              {Object.entries(value).map(([key, innerValue], index) => (
                <Tr key={index}>
                  <Td border="1px solid" borderColor="gray.300">{key}</Td>
                  <Td border="1px solid" borderColor="gray.300">{renderValue(innerValue)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        );
      } else {
        return String(value);
      }
    };
  
    return (
      <>
        <Modal onClose={toggle} size={'full'} isOpen={isOpen}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Logs</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Table size="sm" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th border="1px solid" borderColor="gray.300">Key</Th>
                    <Th border="1px solid" borderColor="gray.300">Value</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data ? (
                    Object.entries(data).map(([key, value], index) => (
                      <Tr key={index}>
                        <Td border="1px solid" borderColor="gray.300">{key}</Td>
                        <Td border="1px solid" borderColor="gray.300">{renderValue(value)}</Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={2} border="1px solid" borderColor="gray.300">
                        No data available
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default LogsView;