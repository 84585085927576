import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Stack,
    TabList,
    TabPanel,
    TabPanels,
    Table,
    Tabs,
    Tbody,
    Text,
    Textarea,
    Th,
    Thead,
    Tr,
    Td,
    Tab,
    useColorModeValue,
    InputLeftElement,
    IconButton,
    InputGroup
} from '@chakra-ui/react'
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';
// Custom components
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Select } from '@chakra-ui/react'
import moment from 'moment/moment';
import Pagination from '../Dashboard/Pagination';
import JsonTable from '../../components/Tables/JsonTable';
import PickersRange from '../Datepicker';

// import {  createColumn } from 'react-chakra-pagination';

import { Icon } from '@chakra-ui/icons';
import { GrFormView } from 'react-icons/gr';
import LogsView from '../../components/Tables/LogsviewDialog';
import { axiosInstance } from '../../network';
import { ApiEndPoints } from '../../network/endpoint';
function Logs() {

    // const columnHelper = createColumn;
    // const columns = [
    //     columnHelper.accessor('name', {
    //       cell: info => info.getValue(),
    //       header: 'Name',
    //     }),
    //     columnHelper.accessor('email', {
    //       cell: info => info.getValue(),
    //       header: 'Email',
    //     }),
    //     columnHelper.accessor('phone', {
    //       cell: info => info.getValue(),
    //       header: 'Phone',
    //     }),
    //     columnHelper.accessor('birthday', {
    //       cell: info => info.getValue(),
    //       header: 'Birthday',
    //     }),
    //     columnHelper.accessor('action', {
    //       cell: info => info.getValue(),
    //       header: '',
    //     }),
    //   ];
    const textColor = useColorModeValue("gray.700", "white");
    let searchIcon = useColorModeValue("gray.700", "gray.200");
    let mainText = useColorModeValue("gray.700", "gray.200");
    let mainTeal = useColorModeValue("teal.300", "teal.300");
    let inputBg = useColorModeValue("white", "gray.800");
    const storedToken = `Bearer ${window.localStorage.getItem("VEROXAI_access_token")}`
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [action, setaction] = useState("")
    const [status, setstatus] = useState("")
    const [usertype, setusertype] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalpage] = useState("");

    const [startDateRange, setStartDateRange] = useState(null);
    const [endDateRange, setEndDateRange] = useState(null);
    const [isLogViewDialogOpen, setLogViewDialogOpen] = useState(false)
    const [logViewDialogData, setLogViewDialogData] = useState(null);
    const fetchTableData = () => {
        setLoading(true);
        const params = {
            f_action: action,
            page: currentPage,
            f_status: status,
            f_user_type: usertype,
            f_timestamp: `${moment(startDateRange).format("DD/MM/YYYY")},${moment(endDateRange).format("DD/MM/YYYY")}`
        }
    
        axiosInstance
            .get(ApiEndPoints.admin.Logs,{ params: params })
            .then(async res => {
                setData(res.data.logs);
                setTotalpage(res.data.totalPages);
                // setTotaldata(res.data.total)
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false);
            })
    }
    const handlePlanChange = (event) => {
        setaction(event.target.value);
        setCurrentPage(1);
    };
    const handlestatuschange = (event) => {
        setstatus(event.target.value);
        setCurrentPage(1);
    }
    const handleusertypechange = (event) => {
        setusertype(event.target.value);
        setCurrentPage(1);
    }

    useEffect(() => {
        fetchTableData();
    }, [action, currentPage, status, usertype, startDateRange, endDateRange])



    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);

    };
    const toggleLogViewDialog = (data) => {
        setLogViewDialogOpen(prev => !prev);
        setLogViewDialogData(data);
    }
    const handleLogViewClick = (data) => {
        toggleLogViewDialog(data);
    };
    return (
        <>
            <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
                <Flex justify="space-between" align="center">
                    <Text
                        color={textColor}
                        fontWeight="bold"
                        fontSize="3xl"
                    >
                        Logs
                    </Text>
                    <Flex justify="space-between" align="center">
                        {/* <InputGroup
                            cursor="pointer"
                            bg={inputBg}
                            borderRadius="15px"
                            w={{
                                sm: "128px",
                                md: "200px",
                            }}
                            me={{ sm: "auto", md: "20px" }}
                            _focus={{
                                borderColor: { mainTeal },
                            }}
                            _active={{
                                borderColor: { mainTeal },
                            }}
                        >
                            <InputLeftElement
                                children={
                                    <IconButton
                                        bg="inherit"
                                        borderRadius="inherit"
                                        _hover="none"
                                        _active={{
                                            bg: "inherit",
                                            transform: "none",
                                            borderColor: "transparent",
                                        }}
                                        _focus={{
                                            boxShadow: "none",
                                        }}
                                        icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
                                    ></IconButton>
                                }
                            />
                            <Input
                                fontSize="xs"
                                py="11px"
                                color={mainText}
                                placeholder="Type here..."
                                borderRadius="inherit"
                            />
                        </InputGroup> */}
                        <Flex justify="space-between" align="center">
                            <Box mr="20px">
                                <Text fontSize="s" mb="4px">
                                    Date
                                </Text>
                                <PickersRange startDateRange={startDateRange} endDateRange={endDateRange} onChange={(dates) => {
                                    const [start, end] = dates;
                                    setStartDateRange(start);
                                    setEndDateRange(end);
                                    setCurrentPage(1);
                                }} />
                            </Box>

                            <Box mr="20px"> {/* Add margin-right to the first Box */}
                                <Text fontSize="s" mb="4px">
                                    Usertype
                                </Text>
                                <Select width="200px" value={usertype} onChange={handleusertypechange}>
                                    <option value="">All</option>
                                    <option value="sys">sys</option>
                                    <option value="user">user</option>
                                </Select>
                            </Box>
                            <Box mr="20px"> {/* Add margin-right to the first Box */}
                                <Text fontSize="s" mb="4px">
                                    status
                                </Text>
                                <Select width="200px" value={status} onChange={handlestatuschange}>
                                    <option value="">All</option>
                                    <option value="success">success</option>
                                    <option value="error">error</option>
                                    <option value="info ">info </option>
                                </Select>
                            </Box>

                            <Box>
                                <Text fontSize="s" mb="4px">
                                    Action
                                </Text>
                                <Select width="200px" value={action} onChange={handlePlanChange}>
                                    <option value="">All</option>
                                    <option value="BINANCE_KEYS">BINANCE_KEYS</option>
                                    <option value="FETCH_WALLET">FETCH_WALLET</option>
                                    <option value="BOT_BUY">BOT_BUY</option>
                                    <option value="BOT_SELL">BOT_SELL</option>
                                    <option value="BOT_PROCESS ">BOT_PROCESS </option>
                                </Select>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
                <Box overflowX="auto" mt="80px">
                    <Table >
                        <Thead>
                            <Th fontSize="lg">Status</Th>
                            <Th fontSize="lg">Action</Th>
                            <Th fontSize="lg">User</Th>
                            <Th fontSize="lg">Message</Th>
                            <Th fontSize="lg">Time</Th>
                            <Th fontSize="lg">Data</Th>

                        </Thead>
                        <Tbody>
                            {data.map((item, indexe) => {
                                console.log("item", item.data);
                                const dateFormat = moment(item.timestamp).utc().format('llll');
                                return <>
                                    <Tr key={item._id}>
                                        <Td>{item.status}</Td>
                                        <Td>{item.action}</Td>
                                        <Td>{item.userId === "sys" ? <>{item.userId}</> : <>{item.userData.email}</>}</Td>
                                        <Td>{item.message}</Td>
                                        <Td>{dateFormat}</Td>
                                        <Td>
                                            <Icon
                                                as={GrFormView}
                                                boxSize={10}
                                                color="white"
                                                sx={{ filter: "invert(100%) sepia(100%) saturate(2%) hue-rotate(126deg) brightness(118%) contrast(101%)" }}
                                                onClick={() => {
                                                    handleLogViewClick(JSON.parse(item.data));
                                                }}
                                            />
                                        </Td>
                                    </Tr>
                                </>
                            })}
                            {/* Add more rows here */}
                        </Tbody>

                    </Table>
                    <Pagination
                        loading={loading}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                    <LogsView
                        isOpen={isLogViewDialogOpen}
                        toggle={toggleLogViewDialog}
                        data={logViewDialogData}

                    />
                </Box>

            </Flex>
        </>
    )
}

export default Logs