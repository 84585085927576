import {
  CartIcon,
  DocumentIcon,
  HomeIcon,
  PersonIcon,
  StatsIcon,
} from "./components/Icons/Icons";
// import Calendar from "views/Applications/Calendar/index";
// import DataTables from "views/Applications/DataTables/index";
// import Kanban from "views/Applications/Kanban/index";
// import Wizard from "views/Applications/Wizard/index";
// import SignInBasic from "views/Authentication/SignIn/SignInBasic.js";
// import SignInCover from "views/Authentication/SignIn/SignInCover.js";
// import SignInIllustration from "views/Authentication/SignIn/SignInIllustration.js";
// import SignUpBasic from "views/Authentication/SignUp/SignUpBasic.js";
// import SignUpCover from "views/Authentication/SignUp/SignUpCover.js";
// import SignUpIllustration from "views/Authentication/SignUp/SignUpIllustration.js";
// import CRM from "views/Dashboard/CRM";
import DashboardPage from "./pages/Dashboard";
import AdminCell from "./pages/AdminCells";
import User from "./pages/User";
import Logs from "./pages/Logs";
import ActiveTrades from "./pages/Activetrades";
import ResentSignals from "./pages/Resentsignals";
// import OrderDetails from "views/Ecommerce/Orders/OrderDetails/index";
// import OrderList from "views/Ecommerce/Orders/OrderList/index";
// import EditProduct from "views/Ecommerce/Products/EditProduct/index";
// import NewProduct from "views/Ecommerce/Products/NewProduct/index";
// import ProductPage from "views/Ecommerce/Products/ProductPage/index";
// import Billing from "views/Pages/Account/Billing/index";
// import Invoice from "views/Pages/Account/Invoice/index";
// import Settings from "views/Pages/Account/Settings/index";
// import Alerts from "views/Pages/Alerts/index";
// import Charts from "views/Pages/Charts/index";
// import Pricing from "views/Pages/Pricing/index";
// import Overview from "views/Pages/Profile/Overview/index";
// import Projects from "views/Pages/Profile/Projects/index";
// import Teams from "views/Pages/Profile/Teams/index";
// import General from "views/Pages/Projects/General/index";
// import Timeline from "views/Pages/Projects/Timeline/index";
// import RTLPage from "views/Pages/RTL/index";
// import NewUser from "views/Pages/Users/NewUser/index";
// import Reports from "views/Pages/Users/Reports/index";
// import Widgets from "views/Pages/Widgets/index";

const dashRoutes = [
 
  {
    name: "Dashboard",
    path: "/",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    // collapse: true,
    component: DashboardPage,
    layout: "/admin",
    
    // items: [
    //   {
    //     name: "Admin - Calls",
    //     path: "/admin/calls",
    //     component: AdminCell,
    //     layout: "/admin",
    //   },
    //   {
    //     name: "User",
    //     path: "/user",
    //     component: User,
    //     layout: "/user",
    //   },
    //   {
    //     name: "Logs",
    //     path: "/logs",
    //     component: Logs,
    //     layout: "/logs",
    //   },
    //   {
    //     name: "Payments",
    //     path: "/payments",
    //     component: Logs,
    //     layout: "/payments",
    //   },
    // ],
  },
  {
    name: "Admin - Calls",
    path: "/admin/calls",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    component: AdminCell,
    layout: "/admin",
  },
  {
    name: "User",
    path: "/user",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    component: User,
    layout: "/user",
  },
  {
    name: "Logs",
    path: "/logs",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    component: Logs,
    layout: "/logs",
  },
  {
    name: "Payments",
    path: "/payments",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    component: Logs,
    layout: "/payments",
  },
  {
    name: "Active Trades",
    path: "/activetrades",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    component: ActiveTrades,
    layout: "/activetrades",
  },
  {
    name: "Recent Signals",
    path: "/resentsignals",
    icon: <HomeIcon color="inherit" />,
    authIcon: <HomeIcon color="inherit" />,
    component: ResentSignals,
    layout: "/resentsignals",
  },
];

export default dashRoutes;
