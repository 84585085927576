import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  Td,
  Tab,
  Icon,
  useColorModeValue,
  InputLeftElement,
  IconButton,
  InputGroup,
  TextField
} from '@chakra-ui/react'
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';
// Custom components
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
import axios from 'axios';
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { Select } from '@chakra-ui/react'
import moment from 'moment/moment';
import Pagination from '../Dashboard/Pagination';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { PlanNameHelper } from '../../constants/general';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import DatePicker from 'react-datepicker';

// import Logs from '../Logs'

function PickersRange({ onChange ,startDateRange,endDateRange}) {
  // const [startDateRange, setStartDateRange] = useState(null);
  // const [endDateRange, setEndDateRange] = useState(null);
  const handleOnChangeRange = (dates) => {
    onChange(dates)
  };
  
  const CustomInput = forwardRef((props, ref) => {
    const startDateFormatted = props.start && format(props.start, 'MM/dd/yyyy') || "";
    const endDateFormatted = props.end !== null ? format(props.end, 'MM/dd/yyyy') : null;

    const value = `${startDateFormatted}${endDateFormatted !== null ? ' - ' + endDateFormatted : ''}`;

    return <Input inputRef={ref} label={props.label || ''} {...props} value={value} />;
  });
  

  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }} className='demo-space-x'>
        <div>
          <DatePicker
            selectsRange
            maxDate={new Date()}
            monthsShown={2}
            endDate={endDateRange}
            selected={startDateRange}
            startDate={startDateRange}
            shouldCloseOnSelect={true}
            id='date-range-picker-months'
            onChange={handleOnChangeRange}
            autoComplete="off"
            // popperPlacement={popperPlacement}
            customInput={
              <CustomInput
                label='Multiple Months'
                end={endDateRange}
                start={startDateRange}
              />
            }
          />
     
        </div>
      </Box>
    </>
  )
}

export default PickersRange 