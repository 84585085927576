import React, { useEffect, useState } from 'react';
import { Box, Button, Spinner } from '@chakra-ui/react';


const Pagination = ({ currentPage, totalPages, onPageChange, loading}) => {
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      onPageChange(newPage);
    }
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const range = 2;

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= currentPage - range && i <= currentPage + range)
      ) {
        pageButtons.push(
          <Button
            key={i}
            size="sm"
            colorScheme={i === currentPage ? 'blue' : 'gray'}
            onClick={() => handlePageChange(i)}
            isDisabled={loading}
          >
            {i}
          </Button>
        );
      } else if (pageButtons[pageButtons.length - 1] !== '...') {
        pageButtons.push('...');
      }
    }

    return pageButtons;
  };

  return (
    <Box mt="2" sx={{ display: 'flex', justifyContent: 'end' }}>
      <Button
        variant="outline"
        size="sm"
        onClick={() => handlePageChange(currentPage - 1)}
        isDisabled={currentPage === 1 || loading} 
      >
        {loading ? <Spinner size="sm" /> : 'Previous'}
      </Button>
      {renderPageButtons()}
      <Button
        ml="2"
        variant="outline"
        size="sm"
        onClick={() => handlePageChange(currentPage + 1)}
        isDisabled={currentPage === totalPages || loading} 
      >
        {loading ? <Spinner size="sm" /> : 'Next'}
      </Button>
    </Box>
  );
};

export default Pagination;

