import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios';

import theme from './theme/theme';
import App from './App';
import reportWebVitals from './reportWebVitals';

/** Import CSS */
import './assets/css/pud-dashboard-styles.css';
import "react-datepicker/dist/react-datepicker.css";

// ** Contexts
import { AuthProvider } from './context/AuthContext'
axios.defaults.baseURL = 'https://admin-api.veroxai.io';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme} resetCss={false} w='100%' overflow='scroll'>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
