export const NumberFormat = (labelValue) => {

    // if the value is smaller than 1 and greater than -1, simply truncate upto 4 decimal places
    if ((labelValue + "").indexOf("0.") === 0 || (labelValue + "").indexOf("-0.") === 0) {
        if (Number(labelValue) < 0.01 && Number(labelValue) !== 0) {
            return Number(labelValue).toPrecision(3);
        }
        return Number(labelValue).toFixed(4);
    }

    // for all the other values, use complex round-off logic
    labelValue = Number(labelValue);

    var postfix = ""; // to be appended after the value, useful in case of rounding off large value
    // Twelve Zeroes for Trillions
    if (Math.abs(labelValue) >= 1.0e+12) {
        labelValue = (labelValue / 1.0e+12);
        postfix = "T";
        // Nine Zeroes for Billions
    } else if (Math.abs(labelValue) >= 1.0e+9) {
        labelValue = (labelValue / 1.0e+9);
        postfix = "B";
        // Six Zeroes for Millions
    } else if (Math.abs(labelValue) >= 1.0e+6) {
        labelValue = (labelValue / 1.0e+6);
        postfix = "M";
    }
    // truncate resulting value upto two decimal places
    var final = Number(labelValue.toFixed(2)).toLocaleString();
    if (final.indexOf(".") === -1) { // no fractional part, still append two zeros after a decimal point
        return final + ".00" + postfix;
    } else if (final.indexOf(".") === (final.length - 2)) { // only one digit after decimal point, append one more zero
        return final + "0" + postfix;
    }
    return final + postfix;


}