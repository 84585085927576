import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  Tab,
  Icon,
  useColorModeValue
} from '@chakra-ui/react'
// Custom components
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";

import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import { BsCircleFill } from "react-icons/bs";
import AdminCallsTable from '../../components/AdminCalls/AdminCallsTable';

function AdminCell() {

  const textColor = useColorModeValue("gray.700", "white");

  const [inputValue, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [callValue, setCallValue] = useState(null);
  const [priceValue, setPriceValue] = useState("");
  const [targetValue, setTargetValue] = useState("");
  const [stoplossValue, setStoplossValue] = useState("");
  const [coinOptions, setCoinOptions] = useState([]);
  const [adminCalls, setAdminCalls] = useState([]);
  const storedToken = `Bearer ${window.localStorage.getItem("VEROXAI_access_token")}`

  const fetchCoinList = () => {
    axios
      .get("https://coinfeed.veroxai.io/coins/list")
      .then(async res => {
        setCoinOptions(res.data.data.map((o) => ({
          "value": o.id,
          "label": o.name,
          "symbol": o.symbol,
        })).slice(0, 10))
      })
      .catch(err => {

      })
  }

  useEffect(() => {
    fetchCoinList();
  }, [])

  const fetchAdminCalls = useCallback(() => {
    axios.get("/admin-calls/",
      {
        headers: {
          Authorization: storedToken,
        }
      }
    ).then(async res => {
      setAdminCalls(res.data.data);
    }).catch(err => {

    })
  }, [storedToken])

  useEffect(() => {
    fetchAdminCalls()
  }, [fetchAdminCalls])

  const customStyles = {
    option: provided => ({
      ...provided,
      color: 'black'
    }),
  }

  const handleOnChangeCoin = (selectedCoin) => {
    setSelectedCoin(selectedCoin);
  }

  const handleCallChange = (selectedCall) => {
    setCallValue(selectedCall);
  }

  const resetForm = () => {
    setSelectedCoin(null);
    setCallValue(null);
    setPriceValue("");
    setTargetValue("");
    setStoplossValue("");
  }

  const handleSubmit = useCallback((e) => {
    e?.preventDefault();
    setLoading(true);
    let body = {
      coinId: selectedCoin?.value,
      coinSymbol: selectedCoin?.symbol,
      coin: selectedCoin?.label,
      call: callValue?.value,
      price: priceValue,
      target: targetValue,
      stoploss: stoplossValue
    }
    axios
      .post("/admin-calls/", body,
        {
          headers: {
            Authorization: storedToken,
          }
        }
      )
      .then(async res => {
        setLoading(false);
        resetForm()
        fetchAdminCalls()
      })
      .catch(err => {
        setLoading(false);
      })
  }, [callValue?.value, fetchAdminCalls, priceValue, selectedCoin?.label, selectedCoin?.symbol, selectedCoin?.value, stoplossValue, storedToken, targetValue])


  const handleInputChange = value => {
    setValue(value);
  };

  const loadOptions = async (inputValue) => {

    let result = await fetch(`https://coinfeed.veroxai.io/coins-list?q=${inputValue}`)
      .then(res => res.json())
      .then(res => res.data)
      .catch(err => []);
    return result.map((o) => ({
      "value": o.coin_id,
      "label": o.name,
      "symbol": o.symbol,
    }));
  };

  return (
    <>
      <Flex
        direction="column"
        // minH="100vh"
        align="center"
        pt={{ sm: "120px", md: "75px" }}
      >
        <Tabs variant="unstyled" mt="24px">
          <TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
            <TabPanel>
              <Card>
                <CardHeader mb="40px">
                  <Flex direction="column">
                    <Text
                      color={textColor}
                      fontSize="lg"
                      fontWeight="bold"
                      mb="3px"
                    >
                      Add New Call
                    </Text>
                  </Flex>
                </CardHeader>
                <CardBody >
                  <Flex direction="column" w="100%">
                    <Grid
                      templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }}
                      templateRows={{ md: "repeat(2, 1fr)" }}
                      gap="24px"
                    >

                      <FormControl w="100%">
                        <FormLabel fontWeight="semibold" fontSize="xs" mb="10px">
                          Coin
                        </FormLabel>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          value={selectedCoin}
                          styles={customStyles}
                          loadOptions={loadOptions}
                          onInputChange={handleInputChange}
                          onChange={handleOnChangeCoin}
                        />
                      </FormControl>
                      <FormControl w="100%">
                        <FormLabel fontWeight="semibold" fontSize="xs" mb="10px">
                          CALL
                        </FormLabel>
                        <Select
                          borderRadius="15px"
                          color="gray.400"
                          fontSize="xs"
                          options={[
                            { value: 'buy', label: 'Buy' },
                            { value: 'sell', label: 'Sell' },
                          ]}
                          styles={customStyles}
                          value={callValue}
                          onChange={handleCallChange}
                        />
                      </FormControl>
                      <FormControl >
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          PRICE
                        </FormLabel>
                        <Input
                          type="number"
                          borderRadius="15px"
                          fontSize="xs"
                          value={priceValue}
                          onChange={e => setPriceValue(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          TARGET
                        </FormLabel>
                        <Input
                          type="number"
                          borderRadius="15px"
                          fontSize="xs"
                          value={targetValue}
                          onChange={e => setTargetValue(e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          type="number"
                          color={textColor}
                          fontWeight="bold"
                          fontSize="xs"
                        >
                          STOP LOSS
                        </FormLabel>
                        <Input
                          type="number"
                          borderRadius="15px"
                          fontSize="xs"
                          value={stoplossValue}
                          onChange={e => setStoplossValue(e.target.value)}
                        />
                      </FormControl>

                    </Grid>
                    <Button
                      variant="no-hover"
                      bg="linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                      alignSelf="flex-end"
                      mt="24px"
                      w="100px"
                      h="35px"
                      onClick={handleSubmit}
                    >
                      <Text fontSize="xs" color="#fff" fontWeight="bold">
                        SUBMIT
                      </Text>
                    </Button>
                  </Flex>
                </CardBody>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      <Flex direction="column" pt={{ base: "150px", lg: "75px" }}>
        <AdminCallsTable data={adminCalls} />
      </Flex>

    </>
  )
}

export default AdminCell