import axios from 'axios';
import { LocalStorageKeys } from '../constants/constatnts';
import { toast } from 'react-toastify';

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "authorization": `Bearer ${window.localStorage.getItem(LocalStorageKeys.VEROX_TOKEN)}`,
  },
});

// You can set default headers like Content-Type here
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

// Add a request interceptor to set the authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from localStorage and set the authorization header
    const token = window.localStorage.getItem(LocalStorageKeys.VEROX_TOKEN);
    if (token) {
      config.headers['authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  });


// Add an interceptor to handle 401 responses - Invalid/Expired token
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {

      // Handle the 401 response here, e.g., by redirecting to a login page
      // You can also clear the token from localStorage if needed
      console.log("Unauthorized. Redirecting to login page...");
      localStorage.removeItem(LocalStorageKeys.VEROX_TOKEN);
      localStorage.removeItem(LocalStorageKeys.VEROX_UID);
      localStorage.removeItem(LocalStorageKeys.VEROX_USER_DISPLAY_NAME);
      localStorage.removeItem(LocalStorageKeys.VEROX_EMAIL);
      localStorage.removeItem(LocalStorageKeys.VEROX_SIGNIN);
      localStorage.removeItem(LocalStorageKeys.VEROX_USER);
      window.location.reload();
      toast.error('Session expired. Please log in again.', {
        position: 'top-center',
        autoClose: 5000,
      });
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 responses
// axiosInstance.interceptors.response.use(
//     (response) => {
//       // If the response is successful, return it
//       return response;
//     },
//     (error) => {
//       // If the response status is 401 (unauthorized), redirect to the login page
//       if (error.response.status === 401) {
//         // Redirect to the login page
//         history.push('/login');
//       }
//       // Return the error
//       return Promise.reject(error);
//     }
//   );
