// export const ADMIN_API_URL = "http://localhost:8020";
export const ADMIN_API_URL = "https://admin-api.veroxai.io";

export const ApiEndPoints = {
  admin: {
    Dashboard: `${ADMIN_API_URL}/dashboard/stats`,
    Dashboard_Sales: `${ADMIN_API_URL}/dashboard/sales/by-month`,
    Users: `${ADMIN_API_URL}/users/`,
    Logs: `${ADMIN_API_URL}/logs/`,
    Payments: `${ADMIN_API_URL}/payments/`,
    Active_Trads: `${ADMIN_API_URL}/active-trades`,
    Resent_Signals: `${ADMIN_API_URL}/signals/recent`
  },

}

