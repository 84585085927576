import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  Tabs,
  Tbody,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  Td,
  Tab,
  Icon,
  useColorModeValue,
  InputLeftElement,
  IconButton,
  InputGroup,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox
} from '@chakra-ui/react'
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';
// Custom components
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Select } from '@chakra-ui/react'
import moment from 'moment/moment';
import Pagination from '../Dashboard/Pagination';
import { PiCheckCircleFill, PiWarningFill, PiInfoFill } from 'react-icons/pi';
import PickersRange from '../Datepicker';
import { axiosInstance } from '../../network';
import { ApiEndPoints } from '../../network/endpoint';

// import {  createColumn } from 'react-chakra-pagination';
const PlanNameHelper = {
  "no_plan": "Free",
  "starter": "Starter",
  "pro": "Pro",
  "ultimate": "Ultimate",
}


function BinanceDialog({ isOpen, onClose, data }) {

  const renderValue = (value) => {
    if (typeof value === 'string') {
      return value;
    } else if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index}>{renderValue(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object') {
      return (
        <Table size="sm" colorScheme="gray">
          <Tbody>
            {Object.entries(value).map(([key, innerValue], index) => (
              <Tr key={index}>
                <Td border="1px solid" borderColor="gray.300">{key}</Td>
                <Td border="1px solid" borderColor="gray.300">{renderValue(innerValue)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      );
    } else {
      return String(value);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Binance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isOpen && renderValue(data)}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function User() {
  // const columnHelper = createColumn;
  // const columns = [
  //     columnHelper.accessor('name', {
  //       cell: info => info.getValue(),
  //       header: 'Name',
  //     }),
  //     columnHelper.accessor('email', {
  //       cell: info => info.getValue(),
  //       header: 'Email',
  //     }),
  //     columnHelper.accessor('phone', {
  //       cell: info => info.getValue(),
  //       header: 'Phone',
  //     }),
  //     columnHelper.accessor('birthday', {
  //       cell: info => info.getValue(),
  //       header: 'Birthday',
  //     }),
  //     columnHelper.accessor('action', {
  //       cell: info => info.getValue(),
  //       header: '',
  //     }),
  //   ];
  const customIconStyle = {
    color: 'darkgreen',
    height: '24px',
    width: '24px',
  };
  const customIconStylewarning = {
    color: 'red',
    height: '24px',
    width: '24px',
  };
  const textColor = useColorModeValue("gray.700", "white");
  let searchIcon = useColorModeValue("gray.700", "gray.200");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  const storedToken = `Bearer ${window.localStorage.getItem("VEROXAI_access_token")}`
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [plan, setPlan] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalpage] = useState("");
  const [startDateRange, setStartDateRange] = useState(null);
  const [endDateRange, setEndDateRange] = useState(null);
  const [search, setSearch] = useState("")
  const [isBinanceDialogOpen, setBinanceDialogOpen] = useState(false)
  const [binanceDialogData, setBinanceDialogData] = useState(null)
  const [isActiveBotFilter, setActiveBotFiler] = useState(false)

  const toggleBinanceDialog = (e, binanceData = null) => {
    setBinanceDialogOpen(prev => !prev)
    setBinanceDialogData(binanceData)
  }

  const fetchTableData = () => {
    setLoading(true);
    const params = {
      f_plan: plan,
      page: currentPage,
      f_timestamp: `${moment(startDateRange).format("DD/MM/YYYY")},${moment(endDateRange).format("DD/MM/YYYY")}`,
      q: search,
      f_binance: isActiveBotFilter.toString()
    }
    axiosInstance
      .get(ApiEndPoints.admin.Users, { params: params })
      .then(async res => {
        setData(res.data.users);
        setTotalpage(res.data.totalPages);
      })
      .catch(err => {

      })
      .finally(() => {
        setLoading(false);
      });
  }
  const handlePlanChange = (event) => {
    setPlan(event.target.value);
    setCurrentPage(1)
  };
  const handleChange = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1)
  }

  useEffect(() => {
    fetchTableData();
  }, [plan, currentPage, startDateRange, endDateRange, search, isActiveBotFilter])

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
        <Flex justify="space-between" align="center">
          <Text
            color={textColor}
            fontWeight="bold"
            fontSize="3xl"
          >
            Users
          </Text>
          <div style={{ width: "100%", display: "flex", flexDirection: "column", height: "250px", flexWrap: "wrap", alignContent: "flex-end", gap: "10px" }}>
            <Checkbox checked={isActiveBotFilter} onChange={(e) => setActiveBotFiler(e.target.checked)}>Show active Bot?</Checkbox>
            <InputGroup
              cursor="pointer"
              bg={inputBg}
              borderRadius="15px"
              w={{
                sm: "128px",
                md: "200px",
              }}
              me={{ sm: "auto", md: "20px" }}
              _focus={{
                borderColor: { mainTeal },
              }}
              _active={{
                borderColor: { mainTeal },
              }}
            >
              <InputLeftElement
                children={
                  <IconButton

                    bg="inherit"
                    borderRadius="inherit"
                    _hover="none"
                    _active={{
                      bg: "inherit",
                      transform: "none",
                      borderColor: "transparent",
                    }}
                    _focus={{
                      boxShadow: "none",
                    }}
                    icon={<SearchIcon color={searchIcon} w="15px" mt="52px" h="15px" />}
                  ></IconButton>
                }
              />
              <Input
                mt="30px"
                fontSize="xs"
                py="11px"
                color={mainText}
                placeholder="Type here..."
                borderRadius="inherit"
                onChange={handleChange}
              />
            </InputGroup>
            <Box mr="20px">
              <Text fontSize="s" mb="4px">
                Date
              </Text>
              <PickersRange
                startDateRange={startDateRange}
                endDateRange={endDateRange}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDateRange(start);
                  setEndDateRange(end);
                  setCurrentPage(1);
                }}
              />
            </Box>
            <Box mr="20px">
              <Text fontSize="s" mb="4px">
                Plan
              </Text>
              <Select
                width='200px'
                value={plan}
                onChange={handlePlanChange}
              >
                <option value=''>All</option>
                <option value='no_plan'>Free Plan</option>
                <option value='starter'>Starter Plan</option>
                <option value='pro'>Pro Plan</option>
                <option value='ultimate'>Ultimate Plan</option>
              </Select>
            </Box>
          </div>
        </Flex>
        <Box overflowX="auto" mt="80px">
          <Table >
            <Thead>
              <Tr>
                <Th fontSize="lg">Email</Th>
                <Th fontSize="lg">Status</Th>
                <Th fontSize="lg">Binance</Th>
                <Th fontSize="lg">Plan</Th>
                <Th fontSize="lg">Last Login</Th>
                <Th fontSize="lg">Added On</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, indexe) => {
                const dateFormat = moment(item.lastLogin).utc().format('llll');
                const addedFormat = moment(item.addedOn).utc().format('llll');
                const title = item.expiry_date ? moment(item.expiry_date).utc().format('llll') : "";
                return <>
                  <Tr key={item._id}>
                    <Td>{item.email}</Td>
                    <Td style={{ display: 'flex', justifyContent: 'center' }}>
                      {item.email_verified ? (
                        <>
                          <PiCheckCircleFill title='verified' style={customIconStyle} />
                        </>
                      ) : (
                        <>
                          <PiWarningFill title='unverified' style={customIconStylewarning} />
                        </>
                      )}
                    </Td>
                    <Td>
                      {
                        item.binance && <span style={{ cursor: "pointer" }} onClick={(e) => toggleBinanceDialog(e, item.binance)}>
                          <PiInfoFill />
                        </span>
                      }
                    </Td>
                    <Td title={`${title}`}>{PlanNameHelper[`${item.plan}`]}</Td>
                    <Td>{dateFormat}</Td>
                    <Td>{addedFormat}</Td>
                  </Tr>
                </>
              })}
              {/* Add more rows here */}
            </Tbody>

          </Table>
          <Pagination
            loading={loading}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}

          />

          <BinanceDialog
            isOpen={isBinanceDialogOpen}
            onClose={toggleBinanceDialog}
            data={binanceDialogData}
          />
        </Box>

      </Flex>
    </>
  )
}

export default User
