// Chakra imports
import {
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import TablesReportsRow from "../Tables/TablesTableRow";
import React from "react";

const AdminCallsTable = ({ data }) => {
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card overflowX={{ sm: "scroll", lg: "hidden" }}>
      <CardBody>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" ps="0px" color="gray.400">
              <Th ps="0px" color="gray.400">
                ID
              </Th>
              <Th color="gray.400">Coin</Th>
              <Th color="gray.400">Call</Th>
              <Th color="gray.400">Price</Th>
              <Th color="gray.400">Target</Th>
              <Th color="gray.400">Stoploss</Th>
              <Th color="gray.400">Time</Th>
            </Tr>
          </Thead>
          <Tbody pb="0px">
            {data.map((row, index, arr) => {
              return (
                <TablesReportsRow
                  id={index}
                  coin={row.coin}
                  call={row.call}
                  price={row.price}
                  target={row.target}
                  stoploss={row.stoploss}
                  timestamp={row.timestamp}
                  isLast={index === arr.length - 1 ? true : false}
                  key={index}
                />
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default AdminCallsTable;
