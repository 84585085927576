import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function LineChart({ chartData, chartOptions }) {
  const [data, setData] = useState({ chartData: [], chartOptions: {} });

  useEffect(() => {
    setData({ chartData, chartOptions });
  }, [chartData, chartOptions]);

  return (
    <ReactApexChart
      options={data.chartOptions}
      series={data.chartData}
      type="area"
      width="100%"
      height="100%"
    />
  );
}

export default LineChart;