import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Stack,
    TabList,
    TabPanel,
    TabPanels,
    Table,
    Tabs,
    Tbody,
    Text,
    Textarea,
    Th,
    Thead,
    Tr,
    Td,
    Tab,
    Icon,
    useColorModeValue,
    InputLeftElement,
    IconButton,
    InputGroup
} from '@chakra-ui/react'
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment/moment';
import Pagination from '../Dashboard/Pagination';
import PickersRange from '../Datepicker';
import Select from "react-select";
import { axiosInstance } from '../../network';
import { ApiEndPoints } from '../../network/endpoint';

const options = [
    { label: "BTCUSDT ", value: "bitcoin" },
    { label: "ETHUSDT", value: "ethereum" },
    { label: "BNBUSDT", value: "binancecoin" },
    { label: "NEOUSDT", value: "neo" },
    { label: "LTCUSDT", value: "litecoin" },
    { label: "XRPUSDT", value: "ripple" },
    { label: "EOSUSDT", value: "eos" },
    { label: "DOGEUSDT", value: "dogecoin" },
    { label: "LINKUSDT", value: "chainlink" },
    { label: "UNIUSDT", value: "uniswap" },
    { label: "AVAXUSDT", value: "avalanche-2" },
    { label: "ATOMUSDT", value: "cosmos" },
    { label: "SHIBUSDT", value: "shiba" },
    { label: "ICPUSDT", value: "internet-computer" },
    { label: "MATICUSDT", value: "matic-network" },
    { label: "DOTUSDT", value: "polkadot" },
    { label: "ALGOUSDT", value: "algorand" },
    { label: "BCHUSDT", value: "bitcoin-cash" },
    { label: "TOMOUSDT", value: "tomochain" },
    { label: "INJUSDT", value: "injective-protocol" },
];

const customStyles = {
    option: provided => ({
        ...provided,
        color: 'black'
    }),
}

function ActiveTrades() {

    const textColor = useColorModeValue("gray.700", "white");
    let searchIcon = useColorModeValue("gray.700", "gray.200");
    let mainText = useColorModeValue("gray.700", "gray.200");
    let mainTeal = useColorModeValue("teal.300", "teal.300");
    let inputBg = useColorModeValue("white", "gray.800");
    const storedToken = `Bearer ${window.localStorage.getItem("VEROXAI_access_token")}`
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalpage] = useState("");
    const [selected, setSelected] = useState([]);
    const [startDateRange, setStartDateRange] = useState(null);
    const [endDateRange, setEndDateRange] = useState(null);
    const [loading, setLoading] = useState(true);
    const fetchTableData = () => {
        setLoading(true)
        const params = {
            page: currentPage,
            f_coin: selected.map((item, i) => (item.label)).join(','),
            page: currentPage,
            f_timestamp: `${moment(startDateRange).format("DD/MM/YYYY")},${moment(endDateRange).format("DD/MM/YYYY")}`
        }
        const queryString = new URLSearchParams(params).toString();
        const apiUrl = `https://admin-api.veroxai.io/active-trades?${queryString}`;
        axiosInstance
            .get(ApiEndPoints.admin.Active_Trads, { params: params })
            .then(async res => {
                setData(res.data.activeTrades);
                setTotalpage(res.data.totalPages);
            })
            .catch(err => {

            })
            .finally(() => {
                setLoading(false)
            });
    }


    useEffect(() => {
        fetchTableData();
    }, [currentPage, startDateRange, endDateRange, selected, currentPage])

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);

    };
    const handleSelectChange = (selectedOptions) => {
        setSelected(selectedOptions);

    };

    return (
        <>
            <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
                <Flex justify="space-between" align="center">
                    <Text
                        color={textColor}
                        fontWeight="bold"
                        fontSize="3xl"
                    >
                        ActiveTrades
                    </Text>
                    <Flex justify="space-between" align="center">
                        {/* <InputGroup
                            cursor="pointer"
                            bg={inputBg}
                            borderRadius="15px"
                            w={{
                                sm: "128px",
                                md: "200px",
                            }}
                            me={{ sm: "auto", md: "20px" }}
                            _focus={{
                                borderColor: { mainTeal },
                            }}
                            _active={{
                                borderColor: { mainTeal },
                            }}
                        >
                            <InputLeftElement
                                children={
                                    <IconButton
                                        bg="inherit"
                                        borderRadius="inherit"
                                        _hover="none"
                                        _active={{
                                            bg: "inherit",
                                            transform: "none",
                                            borderColor: "transparent",
                                        }}
                                        _focus={{
                                            boxShadow: "none",
                                        }}
                                        icon={<SearchIcon color={searchIcon} w="15px" h="15px" />}
                                    ></IconButton>
                                }
                            />
                            <Input
                                fontSize="xs"
                                py="11px"
                                color={mainText}
                                placeholder="Type here..."
                                borderRadius="inherit"
                            />
                        </InputGroup> */}
                        <Flex justify="space-between" align="center">
                            <Box mr="20px">
                                <Text fontSize="s" mb="4px">
                                    Date
                                </Text>
                                <PickersRange startDateRange={startDateRange} endDateRange={endDateRange} onChange={(dates) => {
                                    const [start, end] = dates;
                                    setStartDateRange(start);
                                    setEndDateRange(end);
                                    setCurrentPage(1);
                                }} />
                            </Box>
                            <Box mr="20px">
                                <FormControl w="100%">
                                    <FormLabel fontWeight="semibold" fontSize="s" mb="10px">
                                        Coin
                                    </FormLabel>
                                    <div style={{ width: '200px' }}>
                                        <Select
                                            isMulti
                                            borderRadius="15px"
                                            color="gray.400"
                                            fontSize="xs"
                                            styles={customStyles}
                                            options={options}
                                            value={selected}
                                            onChange={handleSelectChange}
                                        />
                                    </div >
                                </FormControl>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>
                <Box overflowX="auto" mt="80px">
                    <Table >
                        <Thead>
                            <Th fontSize="lg">coin</Th>
                            <Th fontSize="lg">quantity</Th>
                            <Th fontSize="lg">email</Th>
                            <Th fontSize="lg">plan</Th>
                            <Th fontSize="lg">addedOn</Th>
                        </Thead>
                        <Tbody>
                            {data.map((item, indexe) => {
                                const addedFormat = item.timestamp
                                    ? moment(item.timestamp).utc().format('llll')
                                    : '-';
                                return <>
                                    <Tr key={item._id}>
                                        <Td>{item.coin}</Td>
                                        <Td>{item.quantity}</Td>
                                        <Td>{item.userData.email}</Td>
                                        <Td>{item.userData.plan}</Td>
                                        <Td>{addedFormat}</Td>
                                    </Tr>
                                </>

                            })}

                        </Tbody>

                    </Table>
                    <Pagination
                        loading={loading}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </Box>

            </Flex>
        </>
    )
}

export default ActiveTrades