// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from '@chakra-ui/react';
import '../assets/css/pud-dashboard-styles.css';

import Footer from '../components/Footer/Footer';

// Custom components
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';

// Layout components
import AdminNavbar from '../components/Navbars/AdminNavbar';
import Sidebar from '../components/Sidebar/Sidebar';
import { SidebarContext } from '../context/SidebarContext';
import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css'; // ES6
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../routes';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from '../theme/theme';

// Custom Chakra theme
import { AppConfig } from '../constants/general';

export default function MainLayout(props) {
    const { children, ...rest } = props;
    // states and functions
    const [sidebarVariant, setSidebarVariant] = useState('transparent');
    const [fixed, setFixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(275);
    // ref for main panel div
    const mainPanel = React.createRef();
    // functions for changing the states from components

    const getRoute = () => {
        return window.location.pathname !== '/admin/full-screen-maps';
    };

    const getActiveRoute = (routes) => {
        let activeRoute = 'Default Brand Text';
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].items);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].items);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].items);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
                    return routes[i].secondaryNavbar;
                }
            }
        }
        return activeNavbar;
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === '/admin') {
                return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
            }
            if (prop.collapse) {
                return getRoutes(prop.items);
            }
            if (prop.category) {
                return getRoutes(prop.items);
            } else {
                return null;
            }
        });
    };

    const { isOpen, onOpen, onClose } = useDisclosure();
    document.documentElement.dir = 'ltr';
    document.documentElement.layout = 'admin';
    // Chakra Color Mode
    return (
        <ChakraProvider theme={theme} resetCss={false} overflow='scroll'>
            <SidebarContext.Provider
                value={{
                    sidebarWidth,
                    setSidebarWidth,
                    toggleSidebar,
                    setToggleSidebar
                }}>
                <Sidebar
                    routes={routes}
                    logoText={AppConfig.TITLE}
                    display='none'
                    sidebarVariant={sidebarVariant}
                    {...rest}
                />
                <MainPanel
                    ref={mainPanel}
                    w={{
                        base: '100%',
                        xl: `calc(100% - ${sidebarWidth}px)`
                    }}>
                    <Portal>
                        <AdminNavbar
                            onOpen={onOpen}
                            logoText={AppConfig.TITLE}
                            brandText={getActiveRoute(routes)}
                            secondary={getActiveNavbar(routes)}
                            fixed={fixed}
                            {...rest}
                        />
                    </Portal>

                    <PanelContent>
                        <PanelContainer>
                            {children}
                        </PanelContainer>
                    </PanelContent>
                    <Footer />
                </MainPanel>
            </SidebarContext.Provider>
        </ChakraProvider>
    );
}
