import {
  Box,
  Flex,
  Grid, SimpleGrid, Stack, Text, useColorModeValue
} from "@chakra-ui/react";
import BarChart from "../../components/Chart/BarChart";
import LineChart from "../../components/Chart/LineChart";
import Globe from "../../components/Globe/Globe";
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon, WalletIcon
} from "../../components/Icon/Icon";

import React, { useEffect, useState } from "react";
import {
  barChartDataDefault,
  barChartOptionsDefault,
  lineChartDataDefault,
  lineChartOptionsDefault
} from "../../constants/charts";
import { salesData } from "../../constants/general";
import ActiveUsers from "../../components/ActiveUser";
import MiniStatistics from "../../components/MiniStatistics";
import SalesByCountry from "../../components/SalesByCountry";
import SalesOverview from "../../components/SalesOverview";
import axios from "axios";
import { NumberFormat } from "../../constants/number-format";
import { axiosInstance } from "../../network";
import { ApiEndPoints } from "../../network/endpoint";

function DashboardPage() {

  const iconTeal = useColorModeValue("teal.300", "teal.300");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");

  const [monthEarning, setMonthearning] = useState("")
  const [monthEarningusd, setMonthearningusd] = useState("")
  const [todayEarning, setTodayearning] = useState("")
  const [todayEarningusd, setTodayearningusd] = useState("")
  const [totalEarning, setTotalearning] = useState("")
  const [totalEarningusd, setTotalearningusd] = useState("")
  const [totalUser, setToatluser] = useState("")
  const [monthUser,setMonthuser]= useState("")
  const[chartData,setChartdata] = useState([])
  const storedToken = `Bearer ${window.localStorage.getItem("VEROXAI_access_token")}`
  const fetchData = () => {
   
    axiosInstance.get(ApiEndPoints.admin.Dashboard).then(async res => {
      setMonthearning(res.data.data.earnings.thisMonth.totalAmount);
      setMonthearningusd(res.data.data.earnings.thisMonth.totalAmountUsd);
      setTodayearning(res.data.data.earnings.today.totalAmount);
      setTodayearningusd(res.data.data.earnings.today.totalAmountUsd);
      setTotalearning(res.data.data.earnings.total.totalAmount);
      setTotalearningusd(res.data.data.earnings.total.totalAmountUsd);
      setToatluser(res.data.data.user.total);
      setMonthuser(res.data.data.user.thisMonth)
    }).catch(err => {

    })
  }
  const fetchChartData = () => {
    axiosInstance.get(ApiEndPoints.admin.Dashboard_Sales).then(async res => { 
      setChartdata(res.data.data)
      console.log(res.data.data);
    }).catch(err => { })
  }
  useEffect(() => {
    fetchData();
    fetchChartData();
  }, [])

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text
        color={textColor}
        fontWeight="bold"
        fontSize="3xl"
        mb="30px"
        ps="20px"
      >
        General Statistics
      </Text>
      <Box
        templateColumns={{ sm: "4fr 1fr", xl: "1.2fr 1fr" }}
        gap="32px"
        maxW="100%"
        w="100%"
      >
        <Box
          minW="700px"
          h="700px"
          position="absolute"
          right="30px"
          top="14%"
          display={{ sm: "none", md: "block" }}
        >
          <Globe />
        </Box>
        <Stack
          direction="column"
          spacing="24px"
          w="100%"
          mb="24px"
          maxW={{ sm: "315px", md: "100%" }}
          zIndex="0"
        >
          <SimpleGrid columns={{ sm: "1", md: "2" }} spacing="24px">
            <MiniStatistics
              title={"Today's Earning"}
              amount={"VRX " + todayEarning}
              earnings={"≈ $" +  NumberFormat(todayEarningusd)}
              percentage={55}
              icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
            <MiniStatistics
              title={"This month Earning"}
              amount={"VRX " + monthEarning}
              earnings={"≈ $" + NumberFormat(monthEarningusd)}
              percentage={5}
              icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
            <MiniStatistics
              title={"New User"}
              amount={monthUser}
              earnings={totalUser}
              percentage={-14}
              icon={
                <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              }
            />
            <MiniStatistics
              title={"Total Earnings"}
              amount={"VRX " + totalEarning}
              earnings={"≈ $" +  NumberFormat(totalEarningusd)}
              percentage={8}
              icon={<CartIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
            />
          </SimpleGrid>
        </Stack>
      </Box>
      <Box
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
        gap="24px"
        mb={{ lg: "26px" }}
      >

        <SalesOverview
          title={"Sales Overview"}
          percentage={5}
          chart={
            <LineChart
              chartData={[{
                name: "Total VRX",
                data: chartData.map((s)=>s.totalAmount),
              }]}
              chartOptions={lineChartOptionsDefault}
            />
          }
        />
      </Box>
    </Flex>
  )
}

export default DashboardPage